import React, { useEffect, useRef, useState } from "react";

import {
    Image,
    Linking,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import Images from "../../../specific/utils/Images";
import AppTheme from "../../utils/Theme";

import { createAnonymousSession, createUserWithGARData, deleteCurrentSession, deleteCurrentUser, getCurrentSession, getCurrentUser, getUserWithGARIDO, getUserWithSessionId, redirectToCASLogin, updateSessionAndTicketForGarUser, updateUserPrefs, updateUserTicketPref } from "../../../specific/services/AppWrite/AppWriteDatabase";
import Main from "../../../specific/components/Main/Main";
import MainOptions from "../../models/MainOptions";
import Footer from "../../designSystem/Footer/Footer";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { checkCASAuthentication, getGARLoginUrl, getTicket, redirectToLoginUrl, validateTicket } from "../../../specific/services/CAS/cas";
import User from "../../data/user/User";
import * as DateUtils from "../../utils/DateUtils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Constants from "../../utils/Constants";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { removeItemFromLocalStorage } from "../../../specific/utils/LocalStorage";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { handleUrl } from "../../../specific/utils/Deeplink/Deeplink";
import { addContactToGeneralAudience } from "../../services/Mailing";
import { initAnalyticsServices, initUserIdForAnalytics, setUserProperty, trackEvent } from "../../services/Tracking/Analytics";
import { getAppTarget, getHost } from "../../../specific/utils/Navigation/Host";
import DeepLink from "../../services/DeepLink/DeepLink";
import Divider from "../../designSystem/Divider/Divider";

interface CasSplashscreenProps extends ComponentProps {

}

const CasSplashscreen = (props: CasSplashscreenProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const { componentId } = props;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [authStatus, setAuthStatus] = useState<string | undefined>(undefined);
    const [garIframeUrl, setGarIframeUrl] = useState<string | undefined>(undefined);
    const [garAuthData, setGarAuthData] = useState(undefined);
    const [authError, setAuthError] = useState<string | undefined>(undefined);
    const [userAccount, setUserAccount] = useState(undefined);

    const user: User = User.getInstance();

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        key: "cas_splashscreen",
        componentId,
        showNavBar: false,
        showMenu: false,
        canGoBack: false,
        safeArea: false
    });

    let navigate: NavigateFunction | null = null;
    let location: Location | null = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
        location = useLocation();
    }

    const getCASUser = async () => {
        try {
            let authResult = null;
            const sessionAuth = sessionStorage.getItem("cas");
            if ((getHost() === "localhost") || (getHost() === "lili-dev-608f0.web.app")) {
                authResult = {
                    user: {
                        IDO: ["localhost_05678"],
                        PRO: ["lili"],
                        P_MS2: 0,
                        UAI: ["0180897C"],
                        P_MEL: ["axel@lili.cool"],
                        NOM: ["de Sainte Marie"],
                        PRE: ["Axel"]
                    },
                    ticket: "12345"
                }
            } else {
                authResult = await checkCASAuthentication();
            }
            return authResult;
        } catch (error) {
            throw (error);
        }
    }

    useEffect(() => {
        if (garIframeUrl !== undefined) {
            redirectToLoginUrl();
        }
    }, [garIframeUrl]);

    useEffect(() => {
        if (garAuthData !== undefined) {
            if (garAuthData.user !== undefined) {
                getUserAccount();
            }
        }
    }, [garAuthData]);

    useEffect(() => {
        if (userAccount !== undefined) {
            checkUserSession(true);
        }
    }, [userAccount]);

    const getUserAccount = async () => {
        try {
            let userFullName: string = "";
            if ((garAuthData.user.PRE !== undefined) && (garAuthData.user.PRE[0] !== undefined)) {
                userFullName = garAuthData.user.PRE[0];
            }
            if ((garAuthData.user.NOM !== undefined) && (garAuthData.user.NOM[0] !== undefined)) {
                if (userFullName.length > 0) {
                    userFullName = userFullName + " ";
                }
                userFullName = userFullName + garAuthData.user.NOM[0];
            }
            setAuthStatus("Bonjour " + userFullName);
            const userIdo: string = garAuthData.user.IDO[0];
            // On regarde si on a déjà un compte en BASE créé avec cet IDO
            const userAccount = await getUserWithGARIDO(userIdo);
            if (userAccount !== null) {
                setAuthStatus("Content de vous revoir");
                setUserAccount(userAccount);
            } else {
                setAuthStatus("Nouvel enregistrement !");
                checkUserSession(false);
            }
        } catch (error) {
            setAuthError(error);
        }
    }

    const checkUrlForTicket = async () => {
        // Dans un premier temps, on va vérifier si on a un ticket d'authentification.
        try {
            setAuthStatus("🎟️ s'il vous plait !");
            const garTicket = getTicket();
            if (garTicket !== null) {
                // Si c'est le cas, on peut procéder à la vérification du ticket.
                setAuthStatus("Merci pour votre 🎟️ !");
                const authData = await validateTicket(garTicket);
                setAuthStatus("Embarquement immédiat !");
                setGarAuthData(authData);
            } else {
                // Si non, on va rappeler l'url du GAR pour récupérer le ticket
                // On va donc récupérer l'URL du GAR à appeler
                const garUrlToCall: string = getGARLoginUrl();
                setAuthStatus("🛰️ Connexion avec le centre de contrôle !");
                setGarIframeUrl(garUrlToCall);
            }
        } catch (error) {
            setAuthError(error.toString());
        }
    }

    const checkUserConnected = async () => {
        try {
            setAuthStatus("Vérification de la liste des passagers. 👨‍🚀 👽");
            let isUserConnected: boolean = true;
            const appTarget = getAppTarget();
            // On récupère le compte connecté au navigateur
            let currentAccount = await getCurrentUser();
            if ((currentAccount !== null) && (currentAccount !== undefined)) {
                if (currentAccount.prefs !== undefined) {
                    if (currentAccount.prefs.ido !== undefined) {
                        // On va récupérer les informations enregistré dans le sessionStorage
                        const sessionAccount =  sessionStorage.getItem("cas");
                        if (sessionAccount === null) {
                            await deleteCurrentSession();
                            user.deleteUser();
                            isUserConnected = false;
                        } else {
                            const sessionAccountData = JSON.parse(sessionAccount);
                            let sessionIDO = "";
                            if ((sessionAccountData !== undefined) && (sessionAccountData.userData !== undefined) && (sessionAccountData.userData.user !== undefined) && (sessionAccountData.userData.user.IDO !== undefined) && (sessionAccountData.userData.user.IDO[0] !== undefined)) {
                                sessionIDO = sessionAccountData.userData.user.IDO[0];
                            }
                            if (currentAccount.prefs.ido !== sessionIDO) {
                                await deleteCurrentSession();
                                user.deleteUser();
                                isUserConnected = false;
                            }
                        }
                    }
                    // On regarde si le compte enregistré provient bien du même environnement
                    if ((currentAccount.prefs.app !== undefined) && (currentAccount.prefs.app !== appTarget)) {
                        await deleteCurrentSession();
                        user.deleteUser();
                        isUserConnected = false;
                    }
                }
            } else {
                isUserConnected = false;
            }
            if (isUserConnected === false) {
                setAuthStatus("Pas de passagers identifiés. 🚫");
                // S'il n'y a pas de compte, on peut lancer la connexion par le GAR
                checkUrlForTicket();
            } else {
                // On va vérifier si une session est en cours
                setAuthStatus("Passager identifié. 👨‍🚀");
                checkUserSession();
            }
        } catch (error) {
            setAuthError(error);
        }
    }

    const extractDataFromGar = () => {
        const { user } = garAuthData;
        const { IDO = [], PRO = [], P_MS2 = "", UAI = [], P_MEL = [], NOM = [], PRE = [] } = user;
        if (IDO[0] !== undefined) {
            let extractedData = {
                ido: IDO[0],
                pro: PRO[0] ?? "",
                uai: UAI[0] ?? "",
                p_ms2: P_MS2,
                firstname: PRE[0] ?? "",
                lastname: NOM[0] ?? "",
                email: "",
                demo: false
            }
            if (P_MEL.length > 0) {
                for (const aMelIndex in P_MEL) {
                    if (Object.prototype.hasOwnProperty.call(P_MEL, aMelIndex)) {
                        const aMel = P_MEL[aMelIndex];
                        if ((aMel.length > 0) && (aMel.indexOf("noreply") === -1)) {
                            extractedData.email = aMel;
                            break;
                        }
                    }
                }
            }
            return extractedData;
        } else {
            const { codeUAI = [], idAbonne = [], role = [], roleCode = [], emailUser = [], nomUser = [], prenomUser = [] } = user;
            if (codeUAI[0] !== undefined) {
                let extractedData = {
                    ido: idAbonne[0],
                    pro: role[0] ?? "",
                    uai: codeUAI[0] ?? "",
                    p_ms2: roleCode,
                    firstname: prenomUser[0] ?? "",
                    lastname: nomUser[0] ?? "",
                    email: emailUser[0] ?? "",
                    demo: true
                }
                return extractedData;
            }
        }
        return {
            ido: "",
            pro: "",
            uai: "",
            p_ms2: "",
            firstname: "",
            lastname: "",
            email: "",
            demo: true
        }
    }

    const checkUserSession = async (withUserAccount?: boolean) => {
        try {
            if (withUserAccount === undefined) {
                setAuthStatus("Vérification du plan de vol. 🛰️");
            } else {
                setAuthStatus("Vérification du plan de vol de notre passager. 🛰️");
            }

            const appTarget = getAppTarget();
            let existingSession = await getCurrentSession();
            if (existingSession === null) {
                setAuthStatus("Aucun plan de vol pour le moment.");
                if (garAuthData !== undefined) {
                    // On a déjà un compte pour alimenter les données
                    setAuthStatus("Définition du plan de vol.");
                    await createAnonymousSession();
                    existingSession = await waitForSessionToBeActive();
                    setAuthStatus("Plan de vol terminé.");
                    const userIdo: string = garAuthData.user.IDO[0];
                    await updateUserPrefs({ ido: userIdo, ticket: garAuthData.ticket, app: appTarget });
                    // Maintenant qu'on a une essesion active, on peut relancer ce process, pour passer à la suite
                    checkUserSession(withUserAccount);
                } else {
                    // On n'a pas de données, on relance la vérification
                    checkUrlForTicket();
                }
            } else {
                // On récupère le compte AUTH - Si on a une session, c'est qu'on a un compte AUTH
                setAuthStatus("Préparation de la cabine.");
                let currentAccount = await getCurrentUser();
                if (withUserAccount === undefined) {
                    // Premier appel
                    // Vu qu'on a une session, on peut voir s'il y a un compte
                    const userDBAccount = await getUserWithGARIDO(currentAccount.prefs.ido);
                    populateUserInstance(userDBAccount);
                } else {
                    // On a déjà récupéré des infos
                    if (withUserAccount === false) {
                        setAuthStatus("Affectation de la cabine.");
                        const garAuthDataExtracted = extractDataFromGar();
                        // On doit créer un compte pour l'utilisateur, car la vérification d'un compte avec l'IDO a échoué
                        const { ido, pro, p_ms2, uai, firstname, lastname, email, demo } = garAuthDataExtracted;
                        await createUserWithGARData({ ido, pro, p_ms2, uai, sessionId: existingSession.$id, ticket: garAuthData.ticket, authId: currentAccount.$id, firstname, email, lastname, demo });
                        checkUserConnected();
                    } else {
                        setAuthStatus("Cabine rénovée.");
                        // L'utilisateur a déjà un compte BASE, on va donc le mettre à jour
                        // Si on arrive ici, c'est qu'on a déjà vérifier la présence d'un compte avec un ticket
                        const ticket: string = garAuthData.ticket;
                        let userAccountUpdated = {...userAccount};
                        if ((ticket !== null) && (ticket !== userAccount.gar_ticket)) {
                            await updateSessionAndTicketForGarUser({ sessionId: existingSession.$id, ticket, userId: userAccount.$id, authId: currentAccount.$id });
                            userAccountUpdated.gar_ticket = ticket;
                            userAccountUpdated.current_session_id = existingSession.$id;
                            userAccountUpdated.current_auth_id = currentAccount.$id;
                        }
                        populateUserInstance(userAccountUpdated);
                    }
                }
            }
        } catch (error) {

        }
    }

    const populateUserInstance = async (userAccount: any) => {
        const appTarget = getAppTarget();
        user.uid = userAccount.$id;
        user.email = userAccount.email;
        user.demo = userAccount.demo;
        let context = "demo";
        if (userAccount.demo === false) {
            context = "tne";
            user.sub = {
                customer_email: userAccount.email,
                date: new Date().getTime(),
                expires_date: DateUtils.getEndOfTheSchoolYearDate(),
                origin: appTarget,
                product_id: "cool.lili.subnr.lastwelve",
                start_date: new Date().getTime(),
                auto_renewing: false
            }
        }
        user.tne_ido = userAccount.IDO;
        user.tne_pro = userAccount.PRO;
        user.tne_p_ms2 = userAccount.P_MS2;
        user.tne_uai = userAccount.UAI;
        user.version = userAccount.version;
        user.build = userAccount.build;
        user.creation_version = userAccount.creation_version;
        user.creation_build = userAccount.creation_build;
        user.code = { code: "202211", key: "tne" };
        user.profile = "teacher";
        user.avatar = "base";
        user.favorite_books = [];
        user.favorite_activities = [];
        user.firstname = userAccount.firstname,
            user.lastname = userAccount.lastname;
        if (user.email !== "noreply") {
            addContactToGeneralAudience({ email: user.email, userType: context, optIn: true, optInCoach: false, uid: user.uid, firstname: user.firstname, lastname: user.lastname });
        }
        // On ajoute les paramètres Amplitude
        initUserIdForAnalytics(user.uid);
        if (user.tne_uai !== undefined) {
            // On va récupérer les 3 premiers chiffres de l'UAI qui contiennent le code département
            // On le transforme en int, pour avoir le vrai numéro du département
            const depAsString: string = user.tne_uai.substring(0, 3);
            const depAsNumber = parseInt(depAsString);
            setUserProperty({ key: "school_department", value: depAsNumber.toString() });
            setUserProperty({ key: "school_uai", value: user.tne_uai });
        }
        setUserProperty({ key: "profile", value: "teacher_" + context });
        setUserProperty({ key: "subscription_status", value: "premium_tne" });
        setUserProperty({ key: "subscription_end_date", value: DateUtils.getNumericDateForAnalyticForTimestamp(DateUtils.getEndOfTheSchoolYearDate()) });
        await AsyncStorage.setItem(Constants.keyUserData, JSON.stringify(user));
        launchTheApp();
    }

    const launchTheApp = () => {
        const ticket: string = getTicket();
        const appTarget = getAppTarget();
        if ((ticket !== null) && (getHost() !== "localhost") && (getHost() !== "lili-dev-608f0.web.app")) {
            // On vire le ticket de l'URL
            if (appTarget === "gar-prod") {
                window.location.href = "https://app-tne.lili.cool/";
            } else if (appTarget === "gar-preprod") {
                window.location.href = "https://app-tne-pp.lili.cool/";
            } else {
                window.location.href = "https://app-tne-demo.lili.cool/";
            }
        } else {
            enterAppOnceConnected();
        }
        setViewDidAppear(true);
    }

    // Voir comment rendre en commun les ordres de navigation entre les 2 splashcreens
    const enterAppOnceConnected = () => {
        // On va regarder si l'URL est splaschreen ou deeplink
        if (Platform.OS === "web") {
            if (location.pathname.indexOf("deeplink") !== -1) {
                let urlToNavigate = location.pathname.replace("/deeplink/", "");
                navigate(decodeURIComponent(urlToNavigate), { state: "deeplink" });
            } else {
                launchApp();
            }
        } else {
            launchApp();
        }
    }

    const launchApp = async () => {
        const initialUrl: string | null = await Linking.getInitialURL();
        if (initialUrl !== null) {
            const deepLink: DeepLink = DeepLink.getInstance();
            deepLink.path = initialUrl;
        }

        let screenTarget = Constants.ScreenAppMenu;
        Navigation.showModal({
            componentId,
            navigate,
            name: screenTarget,
            passProps: {
                context: Constants.CONTEXT.teacher
            }
        });
        // On va regarder si l'utilisateur avait ouvert l'app avec un universal linkg
        if (Platform.OS !== "web") {
            Linking.getInitialURL().then((initialUrl) => {
                handleUrl({ url: initialUrl, isLaunchedUrl: true, componentId, navigate });
            });
        }
    }

    const waitForSessionToBeActive = async () => {
        let existingSession = await getCurrentSession();
        if (existingSession === null) {
            setTimeout(() => {
                waitForSessionToBeActive();
            }, 500);
        } else {
            return existingSession;
        }
    }

    const onViewDidAppear = () => {
        setTimeout(() => {
            checkUserConnected();
            initAnalyticsServices();
        }, 2000);
    }

    const getAuthStatus = () => {
        if (authStatus === undefined) {
            return <View />
        }
        return <Text style={{ fontSize: appTheme.pixelPerfectForFont(6), color: appTheme.white, fontFamily: appTheme.primarySemiBoldFont, textAlign: "center" }}>{authStatus}</Text>
    }

    const getAuthError = () => {
        if (authError === undefined) {
            return <View />
        }

        return <View>
            <Text style={{ fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.alertOverlayColor, fontFamily: appTheme.primaryBoldFont, textAlign: "center" }}>{"Une erreur est survenue lors de votre authentification. Si vous êtes bloqué sur cette page, veuillez transférer l'erreur ci-dessous à : support@lili.cool."}</Text>
            <Text style={{ fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.alertOverlayColor, fontFamily: appTheme.primaryMediumFont, textAlign: "center" }}>{"ErrorStep : " + authStatus + " - ErrorMsg : " + authError}</Text>
        </View>
    }

    const getContentView = () => {
        if (viewDidAppear === false) {
            return <View
                onLayout={onViewDidAppear}
                style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: appTheme.still, justifyContent: "center", alignItems: "center" }}>
                <Image source={images.illTeacherCat} style={{ width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(100) }} resizeMode="contain" />
                <Text style={{ fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.white, fontFamily: appTheme.primaryBoldFont, textAlign: "center" }}>Bienvenue sur LILI</Text>
                <ActivityIndicator loading={true} color={appTheme.white} />
                <Divider />
                {getAuthStatus()}
                {getAuthError()}
            </View>
        }
        return <View
            style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: appTheme.still, justifyContent: "center", alignItems: "center" }}>
            <View style={{ maxWidth: appTheme.getBlockWidth(), marginHorizontal: appTheme.pixelPerfect(20), backgroundColor: appTheme.schoolColor, justifyContent: "center", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(40), paddingVertical: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10) }}>
            <Image source={images.illTeacherCat} style={{ width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(100) }} resizeMode="contain" />
                <Text style={{ fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.white, fontFamily: appTheme.primaryBoldFont, textAlign: "center" }}>Bienvenue sur LILI</Text>
                <Divider />
                {getAuthStatus()}
                {getAuthError()}
            </View>
            <Footer />
        </View>;
    }

    const top = (appTheme.getFullAppHeight() - appTheme.pixelPerfect(67)) / 2;
    const left = (appTheme.getFullScreenWidth() - appTheme.pixelPerfect(121)) / 2;

    return <Main mainOptions={mainOptions} >
        {getContentView()}
    </Main>
}

export default CasSplashscreen;