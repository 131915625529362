import React, { useEffect, useRef, useState } from "react";

import {
    View,
    Text,
    TouchableOpacity,
    TextStyle,
    TextInput,
    Image,
    Dimensions,
    Modal,
    Platform
} from "react-native";
import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";

import Select from 'react-select';

import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { getSessionsActivities, getSessionsCategories } from "../../../specific/utils/LocalStorage";
import Button from "../../designSystem/Button";
import Activity from "../../models/Activity";
import RadioButton from "../../designSystem/RadioButton";
import { setDBActivity } from "../../../specific/services/Specific_Database";
import { resizeFile, updloadActivityAudio, updloadActivityImage, updloadImage, updloadMediaAudio } from "../../../specific/services/Specific_Storage";
import ActivityPlayer from "../Audio/ActivityPlayer";
import { getLanguage } from "../../utils/Localization/Localization";

import { default as IcPlay } from "../../assets/svg/icons/solid/play.svg";
import { default as IcTrash } from "../../assets/svg/lili/ic_trash.svg";
import { default as IcUpload } from "../../assets/svg/icons/solid/paper-plane-solid.svg";
import SVGView from "../../utils/SvgView";
import Alert from "../Notification/Alert";
import Images from "../../../specific/utils/Images";

const activitiesKeyIndexByCategoryKey = {
    "game_to_build": "JAC",
    "yoga": "YOG",
    "my_emotion": "EMO",
    "breath": "RESP",
    "others": "AUT",
    "dedicaces": "DED",
    "meditation": "MED",
    "emotion_game": "JEM",
    "finger_yoga": "YDD",
    "concentration_game": "JAT",
    "impro_game": "JIM",
    "my_entourage": "PRO",
    "mime_game": "JMI",
    "introspection": "REG",
    "world": "MON",
    "poster_instructions": "PAC",
    "workshops": "WOK",
    "musical_yoga": "YOG",
    "school_advices": "PAC",
    "little_champions": "LPC",
    "virelangues": "VIR",
    "advicesForTheCoach": "ADV",
    "trainingWorkshops": "TWK",
    "superHeroesEquipments": "SHE",
    "children_philo_introduction": "SPC",
    "sleepMeditation": "MED",
    "routineSepUp": "ROU",
    "sleepMusic": "SLM",
    "phi_questions": "VQP",
    "wellBeing": "WLB",
    "onboarding_advices": "ADV",
    "super_posters": "POS",
    "super_activities": "WOK",
    "quotes": "QTS",
    "inspiringBio": "BIO",
    "inpireSportAndAdventure": "BIOSA",
    "inpireScienceAndInnovation": "BIOSI",
    "inspireArts": "BIOA",
    "inspireBookAndEducation": "BIOBE",
    "inspireMovieAndMusic": "BIOMM",
    "inspireNatureAndSpace": "BIONS",
    "inspirePolitic": "BIOP"
}

const languagesByKey = {
    "fr": "Français",
    "en": "Anglais"
}

const gamingCategories: string[] = ["impro_game"];
const inspiringCategories: string[] = ["quotes", "inspiringBio", "inpireSportAndAdventure", "inpireScienceAndInnovation", "inspireArts", "inspireBookAndEducation", "inspireMovieAndMusic", "inspireNatureAndSpace", "inspirePolitic"];

interface LHQActivitiesManagementProps {
    goBack?: () => void
}

const LHQActivitiesManagement = (props: LHQActivitiesManagementProps) => {

    const { goBack } = props;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [categories, setCategories] = useState<any>(undefined);
    const [activities, setActivities] = useState<any>(undefined);
    const [category, setCategory] = useState<{ value: string, label: string, section: string } | undefined>(undefined);
    const [activityKey, setActivityKey] = useState<{ value: string, label: string } | undefined | null>(undefined);
    const [activity, setActivity] = useState<Activity | undefined>(undefined);
    const [newActivity, setNewActivity] = useState<boolean>(false);
    const [newImage, setNewImage] = useState<any>(undefined);
    const [newAudioUrls, setNewAudioUrls] = useState<{ file: any, language: string }[]>([]);
    const [launchPlayer, setLaunchPlayer] = useState<boolean>(false);
    const [selectedAudio, setSelectedAudio] = useState<string[]>([]);
    const [newAudioFilesForMedia, setNewAudioFilesForMedia] = useState<{ media_id: string, file: any, language: string }[]>([]);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const alertRef = useRef<Alert>(null);

    const h1Style: TextStyle = {
        fontFamily: appTheme.primaryBoldFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(18)
    }

    const h2Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(16)
    }

    const h3Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(14)
    }

    const labelStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(10)
    }

    const mediaTitleStyle: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(10)
    }

    const attributeTitleStyle: TextStyle = {
        fontFamily: appTheme.primaryBoldFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(12),
        textDecorationLine: "underline"
    }

    const inputStyle: TextStyle = {
        height: appTheme.pixelPerfect(40),
        borderRadius: appTheme.pixelPerfect(10),
        borderWidth: 1,
        borderColor: appTheme.grow,
        padding: appTheme.pixelPerfect(4),
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(6)
    }

    const pStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14)
    }

    const aStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14),
        textDecorationLine: "underline"
    }

    const onViewWillAppear = () => {
        getCategories();
    }

    const getCategories = async () => {
        const sessionCategories = await getSessionsCategories();
        setCategories(sessionCategories);
    }

    const getActivities = async () => {
        const sessionActivities = await getSessionsActivities();
        setActivities(sessionActivities);
    }

    useEffect(() => {
        if (categories !== undefined) {
            getActivities();
        }
    }, [categories]);

    useEffect(() => {
        if (categories !== undefined) {
            setViewDidAppear(true);
        }
    }, [activities]);

    useEffect(() => {
        if (category !== undefined) {
            setActivityKey(undefined);
        }
    }, [category]);

    useEffect(() => {
        if (selectedAudio.length > 0) {
            setLaunchPlayer(true);
        } else {
            setLaunchPlayer(false);
        }
    }, [selectedAudio]);

    useEffect(() => {
        if (newActivity === true) {
            // En fonction de la catégorie, on va avoir certain champs disponible ou non
            // On va d'abord définir la clé de l'activité
            const activityKeyIndex: string = activitiesKeyIndexByCategoryKey[category.value];
            let newActivityKey: string = "";
            let activityNumber: number = 1;
            while (newActivityKey.length === 0) {
                let activityNumberAsString: string = activityNumber.toString();
                if (activityNumber < 10) {
                    activityNumberAsString = "0" + activityNumberAsString;
                }
                const tempActivityKey: string = activityKeyIndex + "_" + activityNumberAsString;
                if (activities.data[tempActivityKey] === undefined) {
                    newActivityKey = tempActivityKey;
                } else {
                    activityNumber = activityNumber + 1;
                }
            }
            let action_type: "audio" | "game" | "audio_chapters" = "audio";
            let action_title: string = "écouter";
            if (gamingCategories.indexOf(category.value) !== -1) {
                action_type = "game";
                action_title = "jouer";
            } else if (inspiringCategories.indexOf(category.value) !== -1) {
                action_type = "audio_chapters";
                action_title = "écouter";
            }
            let activityToAdd: Activity = {
                key: newActivityKey,
                title: {
                    fr: ""
                },
                section_key: category.section,
                access: {
                    isEssential: false,
                    isPremium: false
                },
                action: {
                    audio: {
                        fr: []
                    },
                    title: {
                        fr: action_title
                    },
                    type: action_type
                },
                body: {
                    fr: ""
                },
                category_keys: [category.value],
                img_url: "",
                last_update: new Date().getTime(),
                media: [],
                min_build_number: 5790,
                order: 1,
                status: "draft",
                tags: []
            };
            if (category.value === "impro_game") {
                activityToAdd.duration = 360;
            }
            setActivity(activityToAdd);
        }
    }, [newActivity]);

    useEffect(() => {
        if ((activityKey !== undefined) && (activityKey !== null)) {
            if (activities.data[activityKey.value] !== undefined) {
                setActivity(activities.data[activityKey.value]);
            }
        } else {
            setActivity(undefined);
        }
    }, [activityKey]);

    useEffect(() => {
        if (activity !== undefined) {
            if (category === undefined) {
                const categoryKey: string = activity.category_keys[0];
                if (categories.data[categoryKey] !== undefined) {
                    const category = categories.data[categoryKey];
                    setCategory({ value: categoryKey, label: category.title.fr, section: category.sectionId });
                }
            }
        }
    }, [activity]);




    const onCategorySelectionChange = (e: { value: string, label: string, section: string }) => {
        setCategory(e);
        setActivityKey(null);
    }
  
    const getCategoriesSelectInput = () => {
        let subTypeOptions: { value: string, label: string, section: string }[] = [];
        for (const anActivityTypeKey in categories.data) {
            if (Object.prototype.hasOwnProperty.call(categories.data, anActivityTypeKey)) {
                const element = categories.data[anActivityTypeKey];
                subTypeOptions.push({
                    value: element.key,
                    label: element.key + " - " + element.title.fr,
                    section: element.sectionId
                })
            }
        }
        return <Select
            value={category}
            onChange={(e) => onCategorySelectionChange(e)}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: appTheme.pixelPerfect(30),
                    borderRadius: appTheme.pixelPerfect(10),
                    fontFamily: appTheme.primaryFont
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...styles,
                    fontFamily: appTheme.primaryFont
                })
            }} options={subTypeOptions} />
    }

    const getActivitiesSelectInput = () => {
        let subTypeOptions: { value: string, label: string, section: string }[] = [];
        for (const anActivityTypeKey in activities.data) {
            if (Object.prototype.hasOwnProperty.call(activities.data, anActivityTypeKey)) {
                const element = activities.data[anActivityTypeKey];
                let addActivity: boolean = false;
                if (category === undefined) {
                    addActivity = true;
                } else if ((element.category_keys !== undefined) && (element.category_keys.indexOf(category.value) !== -1)) {
                    addActivity = true;
                }
                if (addActivity === true) {
                    subTypeOptions.push({
                        value: element.key,
                        label: element.key + " - " + element.title.fr,
                        section: element.sectionId
                    });
                }
            }
        }
        return <Select
            value={activityKey}
            onChange={(e) => setActivityKey(e)}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: appTheme.pixelPerfect(30),
                    borderRadius: appTheme.pixelPerfect(10),
                    fontFamily: appTheme.primaryFont
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...styles,
                    fontFamily: appTheme.primaryFont
                })
            }} options={subTypeOptions} />
    }

    const onChangeTitle = (data: { lang: string, value: string }) => {
        const { lang, value } = data;
        let updatedActivity: Activity = { ...activity };
        let updatedTitle = updatedActivity.title;
        updatedTitle[lang] = value;
        updatedActivity.title = updatedTitle;
        setActivity(updatedActivity);
    }

    const getTitles = () => {
        let titleViews = [];
        for (const aTitleKey in activity.title) {
            if (Object.prototype.hasOwnProperty.call(activity.title, aTitleKey)) {
                const aTitleTrad: string = activity.title[aTitleKey];
                titleViews.push(
                    <View key={aTitleKey} style={{ flexDirection: "row", alignItems: "center", height: appTheme.pixelPerfect(40) }}>
                        <Text style={labelStyle}>{languagesByKey[aTitleKey] + " : "}</Text>
                        <TextInput onChangeText={(e) => onChangeTitle({ lang: aTitleKey, value: e })} value={aTitleTrad} style={inputStyle} />
                    </View>
                )
            }
        }
        return titleViews;
    }

    const onChangeDuration = (data: { value: string }) => {
        const { value } = data;
        let updatedActivity: Activity = { ...activity };
        updatedActivity.duration = value;
        setActivity(updatedActivity);
    }

    const getDuration = () => {
        let duration: string = "60";
        if (activity.duration !== undefined) {
            duration = activity.duration;
        }
        return <View key={"duration"} style={{ flexDirection: "row", alignItems: "center", height: appTheme.pixelPerfect(40) }}>
            <Text style={labelStyle}>{"En secondes :"}</Text>
            <TextInput onChangeText={(e) => onChangeDuration({ value: e })} value={duration} style={inputStyle} />
        </View>;
    }

    const onChangeBody = (data: { lang: string, value: string }) => {
        const { lang, value } = data;
        let updatedActivity: Activity = { ...activity };
        let updatedBody = updatedActivity.body;
        updatedBody[lang] = value;
        updatedActivity.body = updatedBody;
        setActivity(updatedActivity);
    }

    const getBodies = () => {
        let bodyViews = [];
        for (const aBodyKey in activity.body) {
            if (Object.prototype.hasOwnProperty.call(activity.body, aBodyKey)) {
                const aBodyTrad: string = activity.body[aBodyKey];
                bodyViews.push(
                    <View key={aBodyKey} style={{ flexDirection: "row", alignItems: "flex-start", height: appTheme.pixelPerfect(160) }}>
                        <Text style={labelStyle}>{languagesByKey[aBodyKey] + " : "}</Text>
                        <TextInput onChangeText={(e) => onChangeBody({ lang: aBodyKey, value: e })} multiline={true} value={aBodyTrad} style={[inputStyle, { width: appTheme.pixelPerfect(320), height: appTheme.pixelPerfect(160) }]} />
                    </View>
                )
            }
        }
        return bodyViews;
    }

    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]);
    }

    const getImage = () => {
        let imageView = <Text style={labelStyle}>Aucune image</Text>;
        if ((activity.img_url !== undefined) && (activity.img_url.length > 0)) {
            imageView = <Image source={{ uri: activity.img_url }} style={{ width: appTheme.pixelPerfect(180), height: appTheme.pixelPerfect(135) }} resizeMode="contain" />;
        }
        let newImageView = <View />;
        let separatorView = <View />;
        if (newImage !== undefined) {
            const imageSource: string = URL.createObjectURL(newImage);
            newImageView = <Image source={{ uri: imageSource }} style={{ width: appTheme.pixelPerfect(180), height: appTheme.pixelPerfect(135) }} resizeMode="contain" />;
            separatorView = <View style={{ width: appTheme.pixelPerfect(40), alignItems: "center", justifyContent: "center", paddingHorizontal: appTheme.pixelPerfect(20) }}>
                <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue }}>{" >"}</Text>
            </View>
        }
        return <View>
            <View style={{ flexDirection: "row" }}>
                {imageView}
                {separatorView}
                {newImageView}
            </View>
            <input type="file" onChange={handleImageChange} />
        </View>
    }

    const updateTag = (aTag: string) => {
        let updatedActivity: Activity = { ...activity };
        let tagArray = updatedActivity.tags;
        if (tagArray.indexOf(aTag) !== -1) {
            // On retire le tag
            tagArray.splice(tagArray.indexOf(aTag), 1);
        } else {
            // On ajout le tag
            tagArray.push(aTag);
        }
        updatedActivity.tags = tagArray;
        setActivity(updatedActivity);
    }

    const getTags = () => {
        let tagsSelection = [];
        const tagsArray: any[] = [
            {
                value: "emotionsTaming",
                name: "Apprivoiser mes émotions"
            },
            {
                value: "focus",
                name: "Muscler ma concentration"
            },
            {
                value: "errorTaming",
                name: "Dompter l'erreur"
            },
            {
                value: "speak",
                name: "Oser à l'oral"
            },
            {
                value: "collective",
                name: "La jouer collectif"
            },
            {
                value: "criticalThinking",
                name: "Réflechir sur la vie"
            },
            {
                value: "selfEsteem",
                name: "Croire en moi"
            }
        ];
        for (const aTagIndex in tagsArray) {
            if (Object.prototype.hasOwnProperty.call(tagsArray, aTagIndex)) {
                const aTag = tagsArray[aTagIndex];
                if (tagsSelection.length > 0) {
                    tagsSelection.push(<Divider size={5} />);
                }
                let isSelected: boolean = activity.tags.indexOf(aTag.value) !== -1;
                tagsSelection.push(<RadioButton value={isSelected} style={{ width: appTheme.pixelPerfect(200) }} centerButton={true} onPress={() => updateTag(aTag.value)}>
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9) }}>{aTag.name}</Text>
                </RadioButton>);
            }
        }
        return tagsSelection;
    }

    const getPremiumChoiceSelection = () => {
        let premiumSelection = [];
        const premiumArray: any[] = [
            {
                value: false,
                name: "Gratuit"
            },
            {
                value: true,
                name: "Premium"
            }
        ];
        for (const aPremiumIndex in premiumArray) {
            if (Object.prototype.hasOwnProperty.call(premiumArray, aPremiumIndex)) {
                const aPremiumDefinition = premiumArray[aPremiumIndex];
                if (premiumSelection.length > 0) {
                    premiumSelection.push(<Divider size={5} />);
                }
                let isSelected: boolean = aPremiumDefinition.value === activity.access.isPremium;
                premiumSelection.push(<RadioButton value={isSelected} style={{ width: appTheme.pixelPerfect(200) }} centerButton={true} onPress={() => updatePremiumValueForActivity(aPremiumDefinition.value)}>
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9) }}>{aPremiumDefinition.name}</Text>
                </RadioButton>);
            }
        }
        return premiumSelection;
    }

    const updatePremiumValueForActivity = (isPremium: boolean) => {
        let updatedActivity: Activity = { ...activity };
        updatedActivity.access = {
            isEssential: false,
            isPremium
        }
        setActivity(updatedActivity);
    }

    const dbUpdateActivity = async () => {
        let updatedActivity: Activity = { ...activity };
        if (newImage !== undefined) {
            const image_url: string = await updloadActivityImage({ imageToUpload: newImage, categoryKey: category.value, activityKey: activity.key });
            updatedActivity.img_url = image_url;
        }
        updatedActivity.last_update = new Date().getTime();
        await setDBActivity({ key: updatedActivity.key, value: updatedActivity });
        setActivityKey(undefined);
        setNewImage(undefined);
    }

    const addNewMedia = () => {
        let updatedActivity: Activity = { ...activity };
        const indexOfTheNewMedia: number = updatedActivity.media.length + 1;
        let suffixForTheMedia: string = indexOfTheNewMedia.toString();
        if (indexOfTheNewMedia < 10) {
            suffixForTheMedia = "0" + suffixForTheMedia;
        }
        let keyForTheNewMedia: string = updatedActivity.key + "_" + suffixForTheMedia;
        if (category.value === "quotes") {
            const newMedia = {
                author: "",
                body: {
                    fr: ""
                },
                id: keyForTheNewMedia,
                title: {
                    fr: ""
                },
                type: "quote",
                url: {
                    fr: ""
                }
            }
            let existingMedias = [...updatedActivity.media];
            existingMedias.push(newMedia);
            updatedActivity.media = existingMedias;
        } else {
            const newMedia = {
                id: keyForTheNewMedia,
                title: {
                    fr: ""
                },
                type: "pdf",
                url: {
                    fr: ""
                }
            }
            let existingMedias = [...updatedActivity.media];
            existingMedias.push(newMedia);
            updatedActivity.media = existingMedias;
        }
        setActivity(updatedActivity);
    }

    const onDeleteConfirmation = (media: any) => {
        let updatedActivity: Activity = { ...activity };
        let existingMedias = [...updatedActivity.media];
        for (const aMediaIndex in existingMedias) {
            if (Object.prototype.hasOwnProperty.call(existingMedias, aMediaIndex)) {
                const aMedia = existingMedias[aMediaIndex];
                if (aMedia.id === media.id) {
                    existingMedias.splice(parseInt(aMediaIndex), 1);
                    break;
                }
            }
        }
        updatedActivity.media = existingMedias;
        setActivity(updatedActivity);
    }

    const onDeleteRequest = (media: any) => {
        let onDeleteRequestTitle: string = "Demande de suppression d'un média";
        let onDeleteRequestBody: string = "Es-tu sûr de vouloir supprimer le média ";
        if (category.value === "quotes") {
            onDeleteRequestTitle = "Demande de suppression d'une citations";
            onDeleteRequestBody = "Es-tu sûr de vouloir supprimer la citation "
        }
        alertRef.current?.showAlert({
            title: onDeleteRequestTitle,
            body: onDeleteRequestBody + media.title.fr,
            primaryButtonTitle: "Confirmer",
            onPressPrimaryButton: () => onDeleteConfirmation(media),
            secondaryButtonTitle: "Annuler"
        });
    }

    const onChangeMediaData = (data: { key: "body" | "title" | "author" | "url" | "date", language?: string, index: number, text: string }) => {
        const { key, language, index, text } = data;
        let updatedActivity: Activity = { ...activity };
        let existingMedias = [...updatedActivity.media];
        let mediaToChange = existingMedias[index];
        let dataToChange = mediaToChange[key];
        if (language !== undefined) {
            let languageToChange = dataToChange[language];
            languageToChange = text;
            dataToChange[language] = languageToChange;
        } else {
            dataToChange = text;
        }
        mediaToChange[key] = dataToChange;
        existingMedias[index] = mediaToChange;
        updatedActivity.media = existingMedias;
        setActivity(updatedActivity);
    }

    const handleAudioFileForMedia = (data: { file: any, language: string, media_id: string }) => {
        const { file, language, media_id } = data;
        let updatedNewAudioFiles = [...newAudioFilesForMedia];
        updatedNewAudioFiles.push({ media_id, language, file });
        setNewAudioFilesForMedia(updatedNewAudioFiles);
    }

    const onDeleteAudioForMediaRequest = (mediaFile: { file: any, language: string, media_id: string }) => {
        const { file, language, media_id } = mediaFile;
        let updatedNewAudioFiles = [...newAudioFilesForMedia];
        for (const aNewAudioFileIndex in updatedNewAudioFiles) {
            if (Object.prototype.hasOwnProperty.call(updatedNewAudioFiles, aNewAudioFileIndex)) {
                const aNewAudioFile = updatedNewAudioFiles[aNewAudioFileIndex];
                if (aNewAudioFile.media_id === media_id) {
                    updatedNewAudioFiles.splice(parseInt(aNewAudioFileIndex), 1);
                    break;
                }
            }
        }
        setNewAudioFilesForMedia(updatedNewAudioFiles);
        file.target.value = null;
    }

    const onUploadAudioForMediaRequest = async (mediaFile: { file: any, language: string, media_id: string }) => {
        try {
            const { file, language, media_id } = mediaFile;
            const result = await updloadMediaAudio({ audioToUpload: file.target.files[0], categoryKey: category.value, activityKey: activityKey.value, mediaKey: media_id, language: "fr", index: 0 });
            for (const aMediaIndex in activity.media) {
                if (Object.prototype.hasOwnProperty.call(activity.media, aMediaIndex)) {
                    const aMedia = activity.media[aMediaIndex];
                    if (aMedia.id === media_id) {
                        onChangeMediaData({ key: "url", language, index: parseInt(aMediaIndex), text: result });
                        break;
                    }
                }
            }
            file.target.value = null;
        } catch (error) {
            //console.log({ error });
        }

    }

    const getMediaCells = () => {
        let mediaCells = [];
        let mediaCellWidth: number = Dimensions.get("screen").width - appTheme.pixelPerfect(80);
        if (mediaCellWidth > appTheme.pixelPerfect(300)) {
            mediaCellWidth === appTheme.pixelPerfect(300);
        }
        for (const aMediaIndex in activity.media) {
            if (Object.prototype.hasOwnProperty.call(activity.media, aMediaIndex)) {
                const aMedia = activity.media[aMediaIndex];
                if (category.value === "quotes") {
                    const audioFileView = aMedia.url.fr.length > 0 ? <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.grow, justifyContent: "center", alignItems: "center" }} onPress={() => setSelectedAudio([aMedia.url.fr])}>
                        <SVGView Component={IcPlay} size={30} color={"#FFF"} />
                    </TouchableOpacity> : <Text style={labelStyle}>{"Aucun fichier audio"}</Text>;
                    // On va regarder s'il y a un nouveau fichier audio
                    let newAudioFile = undefined;
                    for (const aNewAudioFileIndex in newAudioFilesForMedia) {
                        if (Object.prototype.hasOwnProperty.call(newAudioFilesForMedia, aNewAudioFileIndex)) {
                            const aNewAudioFile = newAudioFilesForMedia[aNewAudioFileIndex];
                            if (aNewAudioFile.media_id === aMedia.id) {
                                newAudioFile = aNewAudioFile;
                                break;
                            }
                        }
                    }
                    const newAudioFileButton = newAudioFile === undefined ? <View /> : <View style={{ width: appTheme.pixelPerfect(100), justifyContent: "space-evenly", flexDirection: "row" }}>
                        <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteAudioForMediaRequest(newAudioFile)}>
                            <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onUploadAudioForMediaRequest(newAudioFile)}>
                            <SVGView Component={IcUpload} size={30} color={appTheme.grow} />
                        </TouchableOpacity>
                    </View>;
                    mediaCells.push(<View style={{ width: mediaCellWidth, flexDirection: "row", borderColor: appTheme.grow, borderRadius: 10, borderWidth: appTheme.pixelPerfect(1), padding: appTheme.pixelPerfect(10), marginBottom: appTheme.pixelPerfect(20) }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: "row", flex: 1 }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={mediaTitleStyle}>{"Titre"}</Text>
                                    <Divider />
                                    <TextInput onChangeText={(e) => onChangeMediaData({ key: "title", language: "fr", index: parseInt(aMediaIndex), text: e })} value={aMedia.title.fr} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                                    <Divider />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={mediaTitleStyle}>{"Auteur"}</Text>
                                    <Divider />
                                    <TextInput onChangeText={(e) => onChangeMediaData({ key: "author", index: parseInt(aMediaIndex), text: e })} value={aMedia.author} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                                    <Divider />
                                </View>
                            </View>
                            <Text style={mediaTitleStyle}>{"Citation"}</Text>
                            <Divider />
                            <TextInput onChangeText={(e) => onChangeMediaData({ key: "body", language: "fr", index: parseInt(aMediaIndex), text: e })} value={aMedia.body.fr} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                            <Divider />
                            <View style={{ flexDirection: "row", flex: 1 }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={mediaTitleStyle}>{"Date (facultative)"}</Text>
                                    <Divider />
                                    <TextInput onChangeText={(e) => onChangeMediaData({ key: "date", index: parseInt(aMediaIndex), text: e })} value={aMedia.date} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                                    <Divider />
                                </View>
                                <View style={{ flex: 1, flexDirection: "row" }}>
                                    <View style={{ width: appTheme.pixelPerfect(200) }}>
                                        <Text style={mediaTitleStyle}>{"Fichier audio"}</Text>
                                        <Divider />
                                        {audioFileView}
                                        <Divider />
                                        <input id={aMedia.id} type="file" onChange={(file) => handleAudioFileForMedia({ file, language: "fr", media_id: aMedia.id })} />
                                        <Divider />
                                    </View>
                                    {newAudioFileButton}
                                </View>
                            </View>
                        </View>
                        <View style={{ width: appTheme.pixelPerfect(80), minHeight: appTheme.pixelPerfect(100), justifyContent: "space-evenly" }}>
                            <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteRequest(aMedia)}>
                                <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
                            </TouchableOpacity>
                        </View>
                    </View>)
                } else {
                    let pdfLink: string = "Aucun fichier";
                    if (aMedia.url !== undefined) {
                        if (aMedia.url[getLanguage()] !== undefined) {
                            pdfLink = aMedia.url[getLanguage()];
                        } else {
                            pdfLink = aMedia.url;
                        }
                    }
                    mediaCells.push(<View style={{ width: mediaCellWidth, flexDirection: "row", borderColor: appTheme.grow, borderRadius: 10, borderWidth: appTheme.pixelPerfect(1), padding: appTheme.pixelPerfect(10), marginBottom: appTheme.pixelPerfect(20) }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: "row", flex: 1 }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={mediaTitleStyle}>{"ID"}</Text>
                                    <Divider />
                                    <TextInput editable={false} value={aMedia.id} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                                    <Divider />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={mediaTitleStyle}>{"Titre"}</Text>
                                    <Divider />
                                    <TextInput onChangeText={(e) => onChangeMediaData({ key: "title", language: "fr", index: parseInt(aMediaIndex), text: e })} value={aMedia.title.fr} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                                    <Divider />
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", flex: 1 }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={mediaTitleStyle}>{"Fichier"}</Text>
                                    <Divider />
                                    <Text style={labelStyle}>{pdfLink}</Text>
                                    <Divider />
                                </View>
                            </View>
                        </View>
                        <View style={{ width: appTheme.pixelPerfect(80), minHeight: appTheme.pixelPerfect(100), justifyContent: "space-evenly" }}>
                            <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteRequest(aMedia)}>
                                <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
                            </TouchableOpacity>
                        </View>
                    </View>)
                }
            }
        }
        return mediaCells;
    }

    const handleAudioFileForActivity = (data: { file: any, language: string }) => {
        const { file, language } = data;
        let updatedNewAudioUrls = [...newAudioUrls];
        updatedNewAudioUrls.push({ file, language });
        setNewAudioUrls(updatedNewAudioUrls);
    }

    const onDeleteAudioRequest = (url: string, language: string) => {
        let updatedActivity = { ...activity };
        let activityAction = updatedActivity.action;
        let activityActionAudio = activityAction.audio;
        let activityAudioForLanguage = activityActionAudio[language];
        const indexofUrlToDelete = activityAudioForLanguage.indexOf(url);
        activityAudioForLanguage.splice(indexofUrlToDelete, 1);
        activityActionAudio[language] = activityAudioForLanguage;
        activityAction.audio = activityActionAudio;
        updatedActivity.action = activityAction;
        setActivity(updatedActivity);
    }

    const onUploadAudioForActivity = async (data: { file: any, language: string }) => {
        try {
            const { file, language } = data;
            const result = await updloadActivityAudio({ audioToUpload: file.target.files[0], categoryKey: category.value, activityKey: activityKey.value, language, index: activity.action.audio[language].length });
            let updatedActivity = { ...activity };
            let activityAction = updatedActivity.action;
            let activityActionAudio = activityAction.audio;
            let activityAudioForLanguage = activityActionAudio[language];

            activityAudioForLanguage.push(result);
            activityActionAudio[language] = activityAudioForLanguage;
            activityAction.audio = activityActionAudio;
            updatedActivity.action = activityAction;
            setActivity(updatedActivity);
            setNewAudioUrls([]);
            file.target.value = null;
        } catch (error) {
        }
    }

    const onDeleteNewAudio = (index: string, file: any) => {
        let updatedNewAudioUrls = [...newAudioUrls];
        updatedNewAudioUrls.splice(parseInt(index), 1);
        setNewAudioUrls(updatedNewAudioUrls);
        file.target.value = null;
    }

    const getActivityAudio = () => {
        if (activity !== undefined) {
            let audioFileView = [];
            if (activity.action.audio.fr.length > 0) {
                for (const aNewAudioFileIndex in activity.action.audio.fr) {
                    if (Object.prototype.hasOwnProperty.call(activity.action.audio.fr, aNewAudioFileIndex)) {
                        const element: string = activity.action.audio.fr[aNewAudioFileIndex];
                        audioFileView.push(<View key={element} style={{ flexDirection: "row", width: appTheme.pixelPerfect(400), padding: appTheme.pixelPerfect(10), borderWidth: appTheme.pixelPerfect(1), borderRadius: appTheme.pixelPerfect(10), borderColor: "#BCBCBC" }}>
                            <View style={{ flex: 1 }}>
                                <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.grow, justifyContent: "center", alignItems: "center" }} onPress={() => setSelectedAudio([element])}>
                                    <SVGView Component={IcPlay} size={30} color={"#FFF"} />
                                </TouchableOpacity>
                                <Divider />
                                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfect(8) }}>{element}</Text>
                            </View>
                            <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteAudioRequest(element, "fr")}>
                                <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
                            </TouchableOpacity>
                        </View>)
                    }
                }
            }
            if (newAudioUrls.length > 0) {
                for (const aNewAudioUrlIndex in newAudioUrls) {
                    if (Object.prototype.hasOwnProperty.call(newAudioUrls, aNewAudioUrlIndex)) {
                        const element: { file: any, language: string } = newAudioUrls[aNewAudioUrlIndex];
                        audioFileView.push(<View key={"new_audio_" + aNewAudioUrlIndex} style={{ flexDirection: "row", width: appTheme.pixelPerfect(400), padding: appTheme.pixelPerfect(10), borderWidth: appTheme.pixelPerfect(1), borderRadius: appTheme.pixelPerfect(10), borderColor: "#BCBCBC" }}>
                            <View style={{ flex: 1 }}>
                                <Divider />
                                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfect(12) }}>{element.language}</Text>
                                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfect(12) }}>{element.file.target.files[0].name}</Text>
                            </View>
                            <View>
                                <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteNewAudio(aNewAudioUrlIndex, element.file)}>
                                    <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
                                </TouchableOpacity>
                                <Divider />
                                <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onUploadAudioForActivity(element)}>
                                    <SVGView Component={IcUpload} size={30} color={appTheme.grow} />
                                </TouchableOpacity>
                            </View>
                        </View>)
                    }
                }
            }
            if (audioFileView.length === 0) {
                audioFileView.push(<Text style={labelStyle}>{"Aucun fichier audio"}</Text>);
            }
            const inputView = newAudioUrls.length > 0 ? <View /> : <input id={activity.key} type="file" onChange={(file) => handleAudioFileForActivity({ file, language: "fr" })} />;
            return <View>
                {audioFileView}
                <Divider />
                {inputView}
                <Divider />
            </View>
        }
        return <View />
    }

    const getMedias = () => {
        // On traite les médias à part, car suivant les catégories, ils n'ont pas les mêmes noms / attributs
        if (inspiringCategories.indexOf(category.value) === -1) {
            return <View />
        }
        let mediaTitle: string = "Médias";
        let addAMediaTitle: string = "Ajouter un média";
        if (category.value === "quotes") {
            mediaTitle = "Citations";
            addAMediaTitle = "Ajouter une citation";
        }
        return <View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={attributeTitleStyle}>{mediaTitle}</Text>
                    </View>
                </View>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                        <Button style={{ width: appTheme.pixelPerfect(300) }} title={addAMediaTitle} onPress={() => addNewMedia()} />
                    </View>
                </View>
            </View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    {getMediaCells()}
                </View>
            </View>
            <Divider />
        </View>
    }

    const getActivityForm = () => {
        if ((activity === undefined) || (category === undefined)) {
            return <View />
        }
        if ((inspiringCategories.indexOf(category.value) === -1) && (gamingCategories.indexOf(category.value) === -1)) {
            return <View>
                <Text style={attributeTitleStyle}>Les activités de cette catégorie ne sont pas encore gérées.</Text>
            </View>
        }
        const recordButtonTitle: string = newActivity === true ? "Enregistrer" : "Mettre à jour";
        return <View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#909090" }} />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <Text style={attributeTitleStyle}>ID</Text>
                    <Divider />
                    <TextInput editable={newActivity} value={activity.key} style={[inputStyle, { width: appTheme.pixelPerfect(200) }]} />
                </View>
                <View style={{ flex: 1 }}>
                    <Text style={attributeTitleStyle}>Premium</Text>
                    <Divider />
                    {getPremiumChoiceSelection()}
                </View>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <Divider />
                    <Button style={{ width: appTheme.pixelPerfect(300) }} title={recordButtonTitle} onPress={dbUpdateActivity} />
                </View>
            </View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={attributeTitleStyle}>Titre</Text>
                    </View>
                    <Divider />
                    {getTitles()}
                </View>
                <View style={{ flex: 2 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={attributeTitleStyle}>Introduction</Text>
                    </View>
                    <Divider />
                    {getBodies()}
                </View>
            </View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={attributeTitleStyle}>Durée</Text>
                    </View>
                    <Divider />
                    {getDuration()}
                </View>
                <View style={{ flex: 2 }}>
                </View>
            </View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={attributeTitleStyle}>Image</Text>
                    </View>
                    <Divider />
                    {getImage()}
                </View>
                <View style={{ flex: 2 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={attributeTitleStyle}>Audio</Text>
                    </View>
                    <Divider />
                    {getActivityAudio()}
                </View>
            </View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={attributeTitleStyle}>Tags</Text>
                    </View>
                    <Divider />
                    <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                        {getTags()}
                    </View>
                </View>
            </View>
            <Divider />
            {getMedias()}
        </View>
    }

    const getNewActivityButton = () => {
        return <Button disabled={category === undefined} style={{ width: appTheme.pixelPerfect(300) }} title={"Créer une nouvelle activité"} onPress={() => setNewActivity(true)} />
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewWillAppear} style={{ width: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.grow} loading={true} />
            </View>
        }
        return <View style={{ width: "100%" }}>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
                {getNewActivityButton()}
            </View>
            <Text style={labelStyle}>
                Catégories
            </Text>
            <Divider size={5} />
            {getCategoriesSelectInput()}
            <Divider />
            <Text style={labelStyle}>
                Activités
            </Text>
            <Divider size={5} />
            {getActivitiesSelectInput()}
            <Divider />
            <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

            </View>
            <Divider />
            {getActivityForm()}
        </View>
    }

    const getAudioPlayerModal = () => {
        if ((activity === undefined) || (selectedAudio.length === 0)) {
            return <View />
        }
        return <ActivityPlayer
            title={activity.title[getLanguage()]}
            notificationImage={Platform.OS === "ios" ? activity.img_url : activity.app_image}
            urls={selectedAudio}
            color={appTheme.grow}
            localImage={images[activity.app_image]}
            remoteImage={activity.img_url}
            togglePlayer={() => setSelectedAudio([])} />
    }

    return <View>
        <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
            <TouchableOpacity onPress={() => goBack()}>
                <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                    Dashboard
                </Text>
            </TouchableOpacity>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                {">"}
            </Text>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                Activités
            </Text>
        </View>
        {getContent()}
        <Modal animationType="slide" visible={launchPlayer} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: appTheme.grow + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", backgroundColor: appTheme.grow + "80", width: appTheme.getFullAppWidth(), marginBottom: appTheme.pixelPerfect(20) }}>
                    {getAudioPlayerModal()}
                </View>
            </View>
        </Modal>
        <Alert ref={alertRef} />
    </View>
}

export default LHQActivitiesManagement;